import { registerApplication, start } from "single-spa";
import * as Activity from "./activityFns";
// registerApplication({
//   name: "@single-spa/welcome",
//   app: () => System.import("http://localhost:6003/main.js"),
//   activeWhen: ["/"],
// });

registerApplication({
  name: "@stackways/navbar",
  app: () => System.import("http://stackapp-account.softobiz.net/main.js"),
  activeWhen: Activity.navbar,
});

registerApplication({
  name: "@stackways/apps",
  app: () => System.import("https://mfapps.stackways.io/main.js"),
  activeWhen: Activity.apps,
});

registerApplication({
  name: "@stackways/servers",
  app: () => System.import("https://mfservers.stackways.io/main.js"),
  activeWhen: Activity.servers,
});

registerApplication({
  name: "@stackways/project",
  app: () => System.import("https://mfprojects.stackways.io/main.js"),
  activeWhen: Activity.projects,
});

start();
