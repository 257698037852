function prefix(location: Location, ...prefixes: string[]) {
  console.log("location", location)
  console.log("prefixes", prefixes)
  console.log("location.href", location.href)
  console.log("location.origin", location.origin)
  return prefixes.some(
    (prefix) => location.href.indexOf(`${prefix}`) !== -1
  );
}

export function apps(location: Location) {
  console.log("applications", prefix(location, ...["applications"]))
  return prefix(location, ...["applications"]);
}

export function servers(location: Location) {
  console.log("server", prefix(location, ...["servers"]))
  return prefix(location, ...["servers"]);
}

export function projects(location: Location) {
  console.log("projects", prefix(location, ...["projects"]))
  return prefix(location, ...["projects"]);
}

export const navbar = (location: Location) => true;
